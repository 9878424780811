const frontPartitionConfigs = [
	{
		configs: {
			PARTITION_NAME: 'QHS',
			PARTITION_URN: '$:partition:partition:1',
		},
		hostnames: ['siv2.qhs.fr', /.*siv2-qhs-fr\..+/i, 'localhost'],
	},
	{
		configs: {
			PARTITION_NAME: 'DICOP',
			PARTITION_URN: '$:partition:partition:2',
		},
		hostnames: ['si.dicop.fr', /.*si-dicop-fr\..+/i],
	},
	{
		configs: {
			PARTITION_NAME: 'HPR',
			PARTITION_URN: '$:partition:partition:3',
		},
		hostnames: ['si.compagnie-hpr.com', /.*si-compagnie-hpr-com\..+/i],
	},
	{
		configs: {
			PARTITION_NAME: 'HPRA',
			PARTITION_URN: '$:partition:partition:4',
		},
		hostnames: ['sia.compagnie-hpr.com', /.*sia-compagnie-hpr-com\..+/i],
	},
	{
		configs: {
			PARTITION_NAME: 'CG2D',
			PARTITION_URN: '$:partition:partition:5',
		},
		hostnames: ['si.cg2d.fr', /.*si-cg2d-fr\..+/i],
	},
	{
		configs: {
			PARTITION_NAME: 'RCONTROLCLIM',
			PARTITION_URN: '$:partition:partition:6',
		},
		hostnames: ['si.rcontrolclim.fr', /.*si-rcontrolclim-fr\..+/i],
	},
	{
		configs: {
			PARTITION_NAME: 'IGIENAIR',
			PARTITION_URN: '$:partition:partition:7',
		},
		hostnames: ['si.igienair.com', /.*si-igienair-com\..+/i],
	},
	{
		configs: {
			PARTITION_NAME: 'ADIAMS',
			PARTITION_URN: '$:partition:partition:8',
		},
		hostnames: ['si.adiams.com', /.*si-adiams-com\..+/i],
	},
	{
		configs: {
			PARTITION_NAME: 'AIRSN',
			PARTITION_URN: '$:partition:partition:9',
		},
		hostnames: ['si.airsn.fr', /.*si-airsn-fr\..+/i],
	},
	{
		configs: {
			PARTITION_NAME: 'MAPCLIM',
			PARTITION_URN: '$:partition:partition:10',
		},
		hostnames: ['si.mapclim.fr', /.*si-mapclim-fr\..+/i],
	},
	{
		configs: {
			PARTITION_NAME: 'D2H',
			PARTITION_URN: '$:partition:partition:11',
		},
		hostnames: ['si.d2h-ventilation.com', /.*si-d2h-ventilation-com\..+/i],
	},
	{
		configs: {
			PARTITION_NAME: 'ZAACK',
			PARTITION_URN: '$:partition:partition:12',
		},
		hostnames: ['si.zaack.io', /.*si-zaack-io\..+/i],
	},
	{
		configs: {
			PARTITION_NAME: 'NANOSENSE',
			PARTITION_URN: '$:partition:partition:13',
		},
		hostnames: ['si.nano-sense.com', /.*si-nano-sense-com\..+/i],
	},
];

const getConfig = configs => {
	const config = configs.find(config => {
		return config.hostnames.find(hostname => {
			if (typeof hostname === 'string') {
				return hostname === location.hostname;
			} else if (hostname instanceof RegExp) {
				return hostname.test(location.hostname);
			} else {
				throw `Invalid hostname ${hostname}`;
			}
		});
	});

	if (!config) {
		throw `Config not found for hostname ${location.hostname}`;
	}

	return config.configs;
};

const FrontPartitionConfig = getConfig(frontPartitionConfigs);

export default FrontPartitionConfig;
