import ApiCollection          from 'Collections/ApiCollection';
import OperationCategoryModel from 'Models/intervention/OperationCategoryModel';
import PlanningModel          from 'Models/intervention/PlanningModel';
import TaskOperationModel     from 'Models/intervention/TaskOperationModel';
import { PagedCollection }    from 'modelx/collections/PagedCollection';
import { appStore }           from 'stores';
import { whenAsync }          from 'tools/modelxTools';
import AbstractModelXStore    from './AbstractModelXStore';

export default class InterventionPlanningSidebarStore extends AbstractModelXStore {

	public isLate = false;
	public isRetour = false;
	public taskOperationPagedCollection = new PagedCollection(TaskOperationModel);
	public taskOperationStatusReferences: TaskOperationStatusReference[];

	private _filters: ModelFilters<TaskOperationModel> = {};
	private _page = 1;

	public constructor(taskOperationStatusReferences: TaskOperationStatusReference[], isLate = false, isRetour = false) {
		super();

		this.taskOperationStatusReferences = taskOperationStatusReferences;
		this.isLate = isLate;
		this.isRetour = isRetour;

		this._isLoading = true;
	}

	public clear() {
		super.clear();

		this.setPage(1);
		this.setFilters({});

		return this;
	}

	public async fetch(planningId: id) {
		try {
			this.setIsLoading(true);

			const planning = planningId ? await new PlanningModel({ id: planningId }).fetch() : new PlanningModel();

			this.taskOperationPagedCollection.clear();

			this.taskOperationPagedCollection
				.setItemsPerPage(10)
				.setPage(this._page)
				.setSort('scheduleStartDate', false)
				.setFilters(this._filters);

			if (planning.id) {
				await whenAsync(() => planning.collPlanningOperationCategory.isLoaded);
				await this.taskOperationPagedCollection.listBy(planning.collPlanningOperationCategory.ids, 'operation.operationCategory');
			} else {
				const operationCategoryCollection = await new ApiCollection(OperationCategoryModel).list();
				await this.taskOperationPagedCollection.listBy(operationCategoryCollection.ids, 'operation.operationCategory');
			}

		} finally {
			this.setIsLoading(false);
		}
	}

	public setFilters(filters: ModelFilters<TaskOperationModel>) {
		this._filters = {
			'task.owner.ownerSubPartition.subPartitionUrn': appStore.subPartitionUrn,
			...filters,
		};

		return this;
	}

	public setPage(page: number) {
		this._page = page;

		return this;
	}
}
