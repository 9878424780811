import { EditOutlined }          from '@ant-design/icons';
import AbstractForm              from 'components/AbstractForm/AbstractForm';
import AppForm                   from 'components/AppForm';
import { FormStoreItemProps }    from 'components/AppForm';
import { IButtonEllipsisAction } from 'components/ButtonEllipsis/ButtonEllipsis';
import AbstractApiModel          from 'modelx/models/abstracts/AbstractApiModel';
import React                     from 'react';

export default <T extends AbstractApiModel,
	F extends typeof AbstractForm<{ model?: typeof T; onSuccess?: (model: T) => void }>>(
	model: T,
	form: F,
	formProps: Omit<FormStoreItemProps<F>, 'model'>,
	actionProps: Partial<IButtonEllipsisAction> = {},
): IButtonEllipsisAction => {
	const label = actionProps.label || `Modifier ${model.theStaticLabel}`;

	return {
		icon: <EditOutlined />,
		label,
		onClick: () => AppForm.open(form as never, { model: model as never, ...(formProps as unknown || {}) }, { title: label }),

		...actionProps,
	};
};