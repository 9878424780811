import { computed }           from 'mobx';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export default class BillableStatusModel extends InvoicePrivateApiModel {
	static get path(): string {
		return '/billable_statuses/{?id}';
	}

	public _sorts: ModelSortsExtended<{
		'id': string;
		'label': string;
		'reference': BillableStatusReference;
	}> = {};

	public static cacheDuration = 3600;

	@computed
	public get label(): string {
		return this.get('label', '');
	}

	@computed
	public get reference(): BillableStatusReference {
		return this.get('reference', '');
	}
}
