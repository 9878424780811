import { PagedCollection }                               from 'Collections/PagedCollection';
import EventModel                                        from 'Models/auditer/EventModel';
import CommentModel                                      from 'Models/comment/CommentModel';
import ContactModel                                      from 'Models/directory/ContactModel';
import InterventionModel                                 from 'Models/intervention/InterventionModel';
import InterventionOperationModel                        from 'Models/intervention/InterventionOperationModel';
import InterventionStatusModel                           from 'Models/intervention/InterventionStatusModel';
import OperationCategoryModel                            from 'Models/intervention/OperationCategoryModel';
import OperationModel                                    from 'Models/intervention/OperationModel';
import TaskGroupModel                                    from 'Models/intervention/TaskGroupModel';
import TaskModel                                         from 'Models/intervention/TaskModel';
import TaskOperationModel                                from 'Models/intervention/TaskOperationModel';
import TaskOperationStatusModel                          from 'Models/intervention/TaskOperationStatusModel';
import TaskStatusModel                                   from 'Models/intervention/TaskStatusModel';
import TaskZoneGroupModel                                from 'Models/intervention/TaskZoneGroupModel';
import TaskZoneModel                                     from 'Models/intervention/TaskZoneModel';
import ZoneContactModel                                  from 'Models/intervention/ZoneContactModel';
import BillableItemModel                                 from 'Models/invoice/BillableItemModel';
import BillableModel                                     from 'Models/invoice/BillableModel';
import BillableStatusModel                               from 'Models/invoice/BillableStatusModel';
import BillingFrequencyModel                             from 'Models/invoice/BillingFrequencyModel';
import BillingGroupModel                                 from 'Models/invoice/BillingGroupModel';
import CurrencyModel                                     from 'Models/invoice/CurrencyModel';
import InvoiceModel                                      from 'Models/invoice/InvoiceModel';
import InvoiceStatusModel                                from 'Models/invoice/InvoiceStatusModel';
import InvoiceTypeModel                                  from 'Models/invoice/InvoiceTypeModel';
import InvoicingGroupModel                               from 'Models/invoice/InvoicingGroupModel';
import PaymentModeModel                                  from 'Models/invoice/PaymentModeModel';
import PaymentModeRuleModel                              from 'Models/invoice/PaymentModeRuleModel';
import PaymentModeTypeModel                              from 'Models/invoice/PaymentModeTypeModel';
import VatModel                                          from 'Models/invoice/VatModel';
import StaffMemberModel                                  from 'Models/rh/StaffMemberModel';
import StaffModel                                        from 'Models/rh/StaffModel';
import ActivityModel                                     from 'Models/sales/ActivityModel';
import ActivityTypeActivityCategoryModel                 from 'Models/sales/ActivityTypeActivityCategoryModel';
import ActivityTypeModel                                 from 'Models/sales/ActivityTypeModel';
import ChargeableModeModel                               from 'Models/sales/ChargeableModeModel';
import ChargeableModel                                   from 'Models/sales/ChargeableModel';
import OwnerModel                                        from 'Models/sales/OwnerModel';
import PurchaseOrderTargetModel                          from 'Models/sales/PurchaseOrderTargetModel';
import QuotationActorGroupModel                          from 'Models/sales/QuotationActorGroupModel';
import QuotationActorModel                               from 'Models/sales/QuotationActorModel';
import QuotationBillingGroupModel                        from 'Models/sales/QuotationBillingGroupModel';
import QuotationClosingModel                             from 'Models/sales/QuotationClosingModel';
import QuotationItemActivityChargeableModel              from 'Models/sales/QuotationItemActivityChargeableModel';
import QuotationItemActivityModel                        from 'Models/sales/QuotationItemActivityModel';
import QuotationItemEventModel                           from 'Models/sales/QuotationItemEventModel';
import QuotationItemEventStatusHistoryModel              from 'Models/sales/QuotationItemEventStatusHistoryModel';
import QuotationItemEventStatusModel                     from 'Models/sales/QuotationItemEventStatusModel';
import QuotationItemModel                                from 'Models/sales/QuotationItemModel';
import QuotationItemScheduleConstraintModel              from 'Models/sales/QuotationItemScheduleConstraintModel';
import QuotationModel                                    from 'Models/sales/QuotationModel';
import QuotationRequestModel                             from 'Models/sales/QuotationRequestModel';
import QuotationStatusHistoryModel                       from 'Models/sales/QuotationStatusHistoryModel';
import QuotationStatusModel                              from 'Models/sales/QuotationStatusModel';
import TagModel                                          from 'Models/sales/TagModel';
import ValidationThresholdModel                          from 'Models/sales/ValidationThresholdModel';
import { BILLABLE_STATUS_CREATED }                       from 'constants/BillableStatuses';
import { QUOTATION_ACTOR_GROUP_BUYING }                  from 'constants/QuotationActorGroups';
import { QUOTATION_ACTOR_GROUP_COMMERCIAL }              from 'constants/QuotationActorGroups';
import { QUOTATION_ITEM_EVENT_STATUS_DONE_PENDING }      from 'constants/QuotationItemEventStatuses';
import { computed }                                      from 'mobx';
import { observable }                                    from 'mobx';
import { action }                                        from 'mobx';
import { makeObservable }                                from 'mobx';
import { ApiCollection }                                 from 'modelx/collections/ApiCollection';
import AbstractModelXStore                               from 'stores/AbstractModelXStore';
import { appStore }                                      from 'stores';
import { enterprisePartitionDashboardStore }             from 'stores';
import { authenticationStore }                           from 'stores';
import { groupCollectionByProperty }                     from 'tools/CollectionHelper';
import { indexCollectionByProperty }                     from 'tools/CollectionHelper';
import ConfigProxy                                       from 'tools/ConfigProxy';
import { Rights }                                        from 'tools/Rights';
import { whenAsync }                                     from 'tools/modelxTools';

export default class QuotationDashboardStore extends AbstractModelXStore {

	public activityCollection = new ApiCollection(ActivityModel);
	public activityTypeActivityCategoryCollection = new ApiCollection(ActivityTypeActivityCategoryModel);
	public activityTypeCollection = new ApiCollection(ActivityTypeModel);
	public allInvoiceInvoiceCollection = new ApiCollection(InvoiceModel);
	public allInvoiceRefundCollection = new ApiCollection(InvoiceModel);
	public billableCollection = new ApiCollection(BillableModel);
	public billableItemCollection = new ApiCollection(BillableItemModel);
	public billableStatusCollection = new ApiCollection(BillableStatusModel);
	public billingFrequencyCollection = new ApiCollection(BillingFrequencyModel);
	public billingGroupCollection = new ApiCollection(BillingGroupModel);
	public buyingQuotationActorCollection = new ApiCollection(QuotationActorModel);
	public buyingQuotationActorContactCollection = new ApiCollection(ContactModel);
	public chargeableCollection = new ApiCollection(ChargeableModel);
	public chargeableModeCollection = new ApiCollection(ChargeableModeModel);
	public collectionQuotationRequest = new ApiCollection(QuotationRequestModel);
	public commentSpecialConditionsCollection = new ApiCollection(CommentModel);
	public commercialQuotationActorCollection = new ApiCollection(QuotationActorModel);
	public commercialStaffCollection = new ApiCollection(StaffModel);
	public commercialStaffMemberCollection = new ApiCollection(StaffMemberModel);
	public currencyCollection = new ApiCollection(CurrencyModel);
	public eventCollection = new PagedCollection(EventModel);
	public interventionCollection = new ApiCollection(InterventionModel);
	public interventionOperationCollection = new ApiCollection(InterventionOperationModel);
	public interventionStatusCollection = new ApiCollection(InterventionStatusModel);
	public invoiceInvoiceCollection = new PagedCollection(InvoiceModel);
	public invoiceRefundCollection = new PagedCollection(InvoiceModel);
	public invoiceStatusCollection = new ApiCollection(InvoiceStatusModel);
	public invoiceTypeCollection = new ApiCollection(InvoiceTypeModel);
	public invoicingGroup = new InvoicingGroupModel();
	public operationCategoryCollection = new ApiCollection(OperationCategoryModel);
	public operationCollection = new ApiCollection(OperationModel);
	public owner = new OwnerModel();
	public paymentModeCollection = new ApiCollection(PaymentModeModel);
	public paymentModeRuleCollection = new ApiCollection(PaymentModeRuleModel);
	public paymentModeTypeCollection = new ApiCollection(PaymentModeTypeModel);
	public purchaseOrderTargetCollection = new ApiCollection(PurchaseOrderTargetModel);
	public quotation = new QuotationModel();
	public quotationActorGroupCollection = new ApiCollection(QuotationActorGroupModel);
	public quotationBillingGroup = new QuotationBillingGroupModel();
	public quotationClosingCollection = new ApiCollection(QuotationClosingModel);
	public quotationItemActivityChargeableCollection = new ApiCollection(QuotationItemActivityChargeableModel);
	public quotationItemActivityCollection = new ApiCollection(QuotationItemActivityModel);
	public quotationItemActivityTagCollection = new ApiCollection(TagModel);
	public quotationItemCollection = new ApiCollection(QuotationItemModel);
	public quotationItemEventCollection = new ApiCollection(QuotationItemEventModel);
	public quotationItemEventDonePendingCollection = new ApiCollection(QuotationItemEventModel);
	public quotationItemEventStatusCollection = new ApiCollection(QuotationItemEventStatusModel);
	public quotationItemEventStatusHistoryCollection = new ApiCollection(QuotationItemEventStatusHistoryModel);
	public quotationItemEventToArbitrateCollection = new PagedCollection(QuotationItemEventModel);
	public quotationItemScheduleConstraintCollection = new ApiCollection(QuotationItemScheduleConstraintModel);
	public quotationStatusCollection = new ApiCollection(QuotationStatusModel);
	public quotationStatusHistoryCollection = new ApiCollection(QuotationStatusHistoryModel);
	public staffCollection = new ApiCollection(StaffModel);
	public staffMemberCollection = new ApiCollection(StaffMemberModel);
	public taskCollection = new ApiCollection(TaskModel);
	public taskGroupCollection = new ApiCollection(TaskGroupModel);
	public taskOperationCollection = new ApiCollection(TaskOperationModel);
	public taskOperationStatusCollection = new ApiCollection(TaskOperationStatusModel);
	public taskStatusCollection = new ApiCollection(TaskStatusModel);
	public taskZoneCollection = new ApiCollection(TaskZoneModel);
	public taskZoneGroupCollection = new ApiCollection(TaskZoneGroupModel);
	public validationThresholdCollection = new ApiCollection(ValidationThresholdModel);
	public vatCollection = new ApiCollection(VatModel);
	public zoneContactCollection = new ApiCollection(ZoneContactModel);

	private _quotationId;

	@observable
	private _quotationItemSearch = '';

	public constructor() {
		super();

		makeObservable(this);
	}

	public async checkHasDebitMandate(invoicingGroupUrn: Urn) {
		const { debitMandateCollection } = enterprisePartitionDashboardStore;
		const entrepriseUrn = this.owner.company.enterprise.urn;

		await debitMandateCollection
			.setFilter('invoicingGroup.invoicingOwner.enterpriseUrn', entrepriseUrn)
			.listBy([invoicingGroupUrn], 'invoicingGroup');

		return !!debitMandateCollection.length;
	}

	public async checkIsAllowedDebit(invoicingGroupUrn: Urn) {
		const { debitMandateCollection } = enterprisePartitionDashboardStore;
		const hasDebitMandate = await this.checkHasDebitMandate(invoicingGroupUrn);

		if (hasDebitMandate) {
			await debitMandateCollection.whenIsLoaded(m => m.debitMandateStatus);
			return debitMandateCollection.some(debitMandate => debitMandate.debitMandateStatus.reference === 'actif');
		}

		return false;
	}

	public clear() {
		this.setQuotationItemSearch('');

		return super.clear();
	}

	public listActivities = async (): Promise<void> => {
		const quotationId = this._quotationId;

		this.quotationItemActivityChargeableCollection.setFilter('quotationItemActivity.quotationItem.quotation', quotationId);
		this.quotationItemActivityChargeableCollection.list();

		this.quotationItemActivityCollection.setFilter('quotationItem.quotation', quotationId);
		this.quotationItemActivityCollection.setSort('quotationItem.position');
		this.quotationItemActivityCollection.setSort('position');
		await this.quotationItemActivityCollection.list().then(async () => {
			this.activityCollection
				.listBy(this.quotationItemActivityCollection.map(m => m.getId('activity')))
				.then(async () => {
					const activityTypeIds = this.activityCollection.map(m => m.getId('activityType'));
					await this.activityTypeCollection.listBy(activityTypeIds);
					await this.activityTypeActivityCategoryCollection.listBy(activityTypeIds, 'activityType');
					const operationTypeIds = this.activityTypeCollection.map(m => m.getId('operationType'));
					await this.operationCollection.listBy(operationTypeIds, 'operationType');
				});

			await this.taskZoneCollection
				.listBy(this.quotationItemActivityCollection.map(m => m.getId('location')))
				.then(() => {
					this.zoneContactCollection
						.setFilters({
							'contactType.reference': 'signatory',
						})
						.listBy(this.taskZoneCollection.ids, 'taskZone');
				});

			await this.taskZoneGroupCollection
				.listBy(this.taskZoneCollection.map(m => m.getId('taskZoneGroup')));

			await this.quotationItemActivityTagCollection
				.setFilter('targetUrn', this.quotationItemActivityCollection.urns)
				.list();
		});
	};

	public listEvents = async () => {
		const quotationId = this._quotationId;

		await Promise.all([
			this.quotationItemEventCollection.setFilter('quotationItem.quotation', quotationId).list(),

			this.quotationItemEventDonePendingCollection
				.setFilters({
					'quotationItem.quotation': quotationId,
					'quotationItemEventStatus.reference': [
						QUOTATION_ITEM_EVENT_STATUS_DONE_PENDING.reference,
					],
				})
				.list(),

			this.quotationItemEventStatusHistoryCollection
				.setFilter('quotationItemEvent.quotationItem.quotation', quotationId)
				.list(),
		]);
	};

	public listItems = async () => {
		const quotationId = this._quotationId;

		await this.quotationItemCollection
			.setFilter('quotation', quotationId)
			.setSort('position')
			.list();

		const quotationItemsUrns = this.quotationItemCollection.map(quotationItem => quotationItem.urn);

		if (quotationItemsUrns.length > 0) {
			await Promise.all([
				this.taskCollection
					.setFilter('taskGroup.sourceUrn', quotationItemsUrns)
					.list()
					.then(() => {
						return this.taskGroupCollection.listBy(this.taskCollection.map(t => t.getId('taskGroup')));
					}),

				this.taskOperationCollection
					.setFilters({
						'task.taskMetas.reference': 'quotation_urn',
						'task.taskMetas.value': this.quotation.urn,
					})
					.list(),
			]);
		}
	};

	public listScheduleConstraints = () => {
		const quotationId = this._quotationId;

		return this.quotationItemScheduleConstraintCollection.setFilter('quotationItem.quotation', quotationId).list();
	};

	public async load() {
		const quotationId = this._quotationId;

		this.quotation.setId(quotationId);
		await this.quotation.fetch();

		if (!this.quotation.isLoaded) {
			return;
		}

		await Promise.all([
			this.quotationClosingCollection
				.setFilter('quotation', quotationId)
				.list(),

			this.commentSpecialConditionsCollection
				.setFilter('typeSource.source.entityUrn', this.quotation.urn)
				.setFilter('typeSource.type.reference', 'contract_iteration_special_conditions')
				.list(),

			this.collectionQuotationRequest.listBy([this.quotation.id], 'quotation'),

			this.eventCollection
				.setFilters({
					'subject.metadata.quotation_urn': this.quotation.urn,
					'subject.metadata.recipient_type': 'TO',
				})
				.list(),

			this.interventionCollection
				.setFilter('interventionOperations.taskOperation.task.taskMetas.reference', 'quotation_urn')
				.listBy([this.quotation.urn], 'interventionOperations.taskOperation.task.taskMetas.value')
				.then(() => {
					return this.interventionOperationCollection
						.listBy(this.interventionCollection.ids, 'intervention');
				}),

			this.owner.setId(this.quotation.ownerId).fetch(),

			this.paymentModeCollection.setFilter('partitionUrn', ConfigProxy.get('PARTITION_URN')).list(),

			this.purchaseOrderTargetCollection.setFilter('targetUrn', this.quotation.urn).list(),

			whenAsync(() => this.owner.enterprise.isLoaded).then(async () => {
				if (this.owner.enterprise.getUrn('nafCode')) {
					await this.paymentModeRuleCollection
						.setFilters({
							'paymentMode.partitionUrn': appStore.partition.urn,
							targetUrn: this.owner.enterprise.getUrn('nafCode'),
						})
						.list();
				}
			}),

			this.listActivities(),
			this.listEvents(),
			this.listItems(),
			this.listScheduleConstraints(),
			this.loadActorAndContactCollection(),
			this.loadBillingAndInvoicingInformations(),
			this.loadInvoiceCollection(),
			this.loadNotManageableCollections(),
			this.loadQuotationStatusHistoryCollection(),
		]);

		this.validationThresholdCollection.setFilters({
			'ownerSubPartition.subPartitionUrn': appStore.subPartitionUrn,
			staffMemberUrn: authenticationStore.session.ownerUrn,
		});
		this.validationThresholdCollection.list();
	}

	public loadActorAndContactCollection = async () => {
		const quotationId = this.quotation.id;

		if (!quotationId) {
			console.warn('No quotation id');
			return;
		}

		this.buyingQuotationActorCollection.setFilters({
			quotation: quotationId,
			'quotationActorGroup.reference': QUOTATION_ACTOR_GROUP_BUYING.reference,
		});

		this.buyingQuotationActorCollection.list().then(() => {
			this.buyingQuotationActorContactCollection.listByFromCollection(this.buyingQuotationActorCollection, 'actorId', 'id');
		});

		this.commercialQuotationActorCollection.setFilters({
			quotation: quotationId,
			'quotationActorGroup.reference': QUOTATION_ACTOR_GROUP_COMMERCIAL.reference,
		});
		this.commercialQuotationActorCollection.list().then(async () => {
			await this.commercialStaffMemberCollection.listByFromCollection(this.commercialQuotationActorCollection, 'actorId', 'id');
			await this.commercialStaffCollection
				.listBy(this.commercialStaffMemberCollection.map(m => m.getId('staff')));
		});
	};

	public loadBillingAndInvoicingInformations = async () => {
		this.billingGroupCollection.clear();
		this.invoicingGroup.clear();
		this.quotationBillingGroup.clear();

		if (this.quotation.quotationBillingGroupId) {
			await this.quotationBillingGroup
				.setId(this.quotation.quotationBillingGroupId)
				.fetch()
				.then(() => {
					this.billingGroupCollection
						.setFilter('sourceUrn', this.quotationBillingGroup.urn)
						.list()
						.then(async () => {
							await this.billableCollection
								.listByFromCollection(this.billingGroupCollection, 'id', 'billingGroup', {}, {
									'billableStatus.reference': [BILLABLE_STATUS_CREATED.reference],
								})
								.then(() => {
									this.billableItemCollection.listByFromCollection(this.billableCollection, 'id', 'billable');
								})
								.catch(() => {
									// the user could not have the right to get billables
								});
						});

					this.invoicingGroup.setId(this.quotationBillingGroup.invoicingGroupId);
					this.invoicingGroup.fetch();
				});
		}
	};

	public loadInvoiceCollection = async () => {
		await Promise.all([
			this.allInvoiceInvoiceCollection
				.setFilters({
					'billingGroup.billingGroupMetas.value': this.quotation.urn,
					'invoiceType.reference': 'invoice',
				})
				.list(),

			this.invoiceInvoiceCollection
				.setFilters({
					'billingGroup.billingGroupMetas.value': this.quotation.urn,
					'invoiceType.reference': 'invoice',
				})
				.setSort('date')
				.list(),

			this.allInvoiceRefundCollection
				.setFilters({
					'billingGroup.billingGroupMetas.value': this.quotation.urn,
					'invoiceType.reference': 'refund',
				})
				.list(),

			this.invoiceRefundCollection
				.setFilters({
					'billingGroup.billingGroupMetas.value': this.quotation.urn,
					'invoiceType.reference': 'refund',
				})
				.setSort('date')
				.list(),
		]);
	};

	public loadNotManageableCollections = async () => {
		await Promise.all([
			this.operationCategoryCollection
				.setFilter('partitionUrn', ConfigProxy.get('PARTITION_URN'))
				.list({ cache: 3600 }),
			this.billableStatusCollection.list({ cache: 3600 }),
			this.billingFrequencyCollection.list({ cache: 3600 }),
			this.chargeableCollection.list({ cache: 3600 }),
			this.chargeableModeCollection.list({ cache: 3600 }),
			this.currencyCollection.list({ cache: 3600 }),
			this.interventionStatusCollection.list({ cache: 3600 }),
			this.invoiceStatusCollection.list({ cache: 3600 }),
			this.invoiceTypeCollection.list({ cache: 3600 }),
			this.paymentModeTypeCollection.list({ cache: 3600 }),
			this.quotationActorGroupCollection.list({ cache: 3600 }),
			this.quotationItemEventStatusCollection.list({ cache: 3600 }),
			this.quotationStatusCollection.list(),
			this.taskOperationStatusCollection.list({ cache: 3600 }),
			this.taskStatusCollection.list({ cache: 3600 }),
			this.vatCollection.list(),
		]);
	};

	public loadQuotationStatusHistoryCollection = async () => {
		await this.quotationStatusHistoryCollection
			.setFilter('quotation', this.quotation.id)
			.setSort('createdAt', false)
			.list();

		await this.staffMemberCollection
			.listByFromCollection(this.quotationStatusHistoryCollection, 'createdById', 'id');
		await this.staffCollection.listBy(this.staffMemberCollection.map(m => m.getId('staff')));
	};

	@computed
	public get quotationItemActivityByTaskZoneUrn() {
		return groupCollectionByProperty(this.quotationItemActivityCollection, 'locationUrn');
	}

	@computed
	public get filteredTaskZoneCollection() {
		const filteredTaskZoneCollection = new ApiCollection(TaskZoneModel);
		const search = this.quotationItemSearch.trim().toLowerCase();

		this.taskZoneCollection.forEach(taskZone => {
			// On filtre les résultats selon le champ de recherche
			if (search) {
				const itemActivities = this.quotationItemActivityByTaskZoneUrn[taskZone.urn] || [];

				if (
					// Cherche dans le titre du site
					!taskZone.name.toLowerCase().includes(search)
					// Cherche dans le titre de la prestation
					&& !itemActivities.every(m => m.quotationItem.title?.toLowerCase().includes(search))
				) {
					return false;
				}
			}

			if (this.quotationItemActivityByTaskZoneUrn[taskZone.urn]) {
				filteredTaskZoneCollection.add(taskZone);
			}
		});
		return filteredTaskZoneCollection;
	}

	@computed
	public get taskZoneByTaskZoneGroupIri() {
		return groupCollectionByProperty(this.filteredTaskZoneCollection, 'taskZoneGroup.@id');
	}

	@computed
	public get activityById() {
		return indexCollectionByProperty(this.activityCollection, 'id');
	}

	@computed
	public get taskZoneGroupByIri() {
		return indexCollectionByProperty(this.taskZoneGroupCollection, '@id');
	}

	@computed
	public get chargeableById() {
		return indexCollectionByProperty(this.chargeableCollection, 'id');
	}

	@computed
	public get chargeableModeById() {
		return indexCollectionByProperty(this.chargeableModeCollection, 'id');
	}

	@computed
	public get operationById() {
		return indexCollectionByProperty(this.operationCollection, 'id');
	}

	@computed
	public get quotationItemActivityByQuotationItemId() {
		return groupCollectionByProperty(this.quotationItemActivityCollection, 'quotationItem.id');
	}

	@computed
	public get quotationItemActivityTagByQuotationItemActivityUrn() {
		return groupCollectionByProperty(this.quotationItemActivityTagCollection, 'targetUrn');
	}

	@computed
	public get quotationItemActivityChargeableByQuotationItemActivityId() {
		return groupCollectionByProperty(this.quotationItemActivityChargeableCollection, 'quotationItemActivity.id');
	}

	@computed
	public get quotationItemEventByQuotationItemId() {
		return groupCollectionByProperty(this.quotationItemEventCollection, 'quotationItem.id');
	}

	@computed
	public get quotationItemScheduleConstraintByQuotationItemId() {
		return groupCollectionByProperty(this.quotationItemScheduleConstraintCollection, 'quotationItem.id');
	}

	@computed
	public get quotationIsUpdatable() {
		return Rights.quotation.isUpdatable(this.quotationStatus);
	}

	@computed
	public get quotationEventsAreUpdatable() {
		return Rights.quotation.eventsAreUpdatable(this.quotationStatus);
	}

	@computed
	public get quotationActivitiesVatsAreUpdatable() {
		return Rights.quotation.activitiesVatsAreUpdatable(this.quotationStatus);
	}

	@computed
	public get quotationTotalPriceExclTaxWithoutDiscount() {
		return this.quotation.totalPriceExclTax;
	}

	@computed
	public get quotationTotalPriceExclTaxWithDiscount() {
		return this.quotation.totalPriceExclTax
			- (this.quotation.totalPriceExclTax * this.quotation.discount / 100);
	}

	@computed
	public get billingGroup() {
		return this.billingGroupCollection.last();
	}

	@computed
	public get billingFrequency() {
		const billingFrequency = this.billingFrequencyCollection.find(
			billingFrequency => billingFrequency.id === this.quotationBillingGroup.billingFrequencyId,
		);

		if (!billingFrequency) {
			return new BillingFrequencyModel();
		}

		return billingFrequency;
	}

	@computed
	public get quotationStatus() {
		return this.quotation.quotationStatus;
	}

	@computed
	public get quotationClosing() {
		return this.quotationClosingCollection.first() || undefined;
	}

	@computed
	public get quotationStatusHistoryValidatedByClient() {
		const validatedByClientStatus = this.quotationStatusCollection.find(
			quotationStatus => quotationStatus.reference === 'validated_by_client',
		);

		if (!validatedByClientStatus) {
			return new QuotationStatusHistoryModel();
		}

		const quotationStatusHistoryValidatedByClient = this.quotationStatusHistoryCollection.find(
			quotationStatusHistory => quotationStatusHistory.quotationStatusId === validatedByClientStatus.id,
		);

		if (!quotationStatusHistoryValidatedByClient) {
			return new QuotationStatusHistoryModel();
		}

		return quotationStatusHistoryValidatedByClient;
	}

	@computed
	public get staffQuotationActor() {
		const lastStaffQuotationActor = this.commercialQuotationActorCollection.last();

		if (!lastStaffQuotationActor) {
			return new QuotationActorModel();
		}

		return lastStaffQuotationActor;
	}

	public set quotationId(quotationId) {
		this._quotationId = quotationId;
		this.load();
	}

	@computed
	public get totalStucked() {
		return this.quotationItemEventDonePendingCollection.models.reduce(
			(previousValue, currentValue) => {
				const quotationItem = this.quotationItemCollection
					.find(quotationItem => quotationItem.id === currentValue.getId('quotationItem'));
				if (!quotationItem) {
					return previousValue;
				}

				const totalActivities = this.quotationItemActivityCollection.models.reduce(
					(previousValue, currentValue) => {
						if (currentValue.getId('quotationItem') !== quotationItem.id) {
							return previousValue;
						}

						return previousValue + currentValue.totalPriceExclTax;
					},
					0,
				);

				return previousValue + totalActivities;
			},
			0,
		);
	}

	public get quotationRequests() {
		return this.collectionQuotationRequest.models || [];
	}

	@action
	public setQuotationItemSearch(value: string) {
		this._quotationItemSearch = value;
	}

	public get quotationItemSearch() {
		return this._quotationItemSearch;
	}

	@computed
	public get taskZonesWithoutContactSignatory() {
		const taskZones: TaskZoneModel[] = [];
		if (!this.zoneContactCollection.length) {
			this.taskZoneCollection.forEach(tz => taskZones.push(tz));
			return taskZones;
		}

		this.taskZoneCollection.forEach(tz => {
			if (!this.zoneContactCollection.find(zc => zc.taskZoneId === tz.id)) {
				taskZones.push(tz);
			}
		});

		return taskZones;
	}
}
