import { ApiCollection }                                          from 'Collections/ApiCollection';
import { PagedCollection }                                        from 'Collections/PagedCollection';
import EventModel                                                 from 'Models/auditer/EventModel';
import CommentModel                                               from 'Models/comment/CommentModel';
import ContactModel                                               from 'Models/directory/ContactModel';
import InterventionModel                                          from 'Models/intervention/InterventionModel';
import InterventionOperationModel                                 from 'Models/intervention/InterventionOperationModel';
import InterventionStatusModel                                    from 'Models/intervention/InterventionStatusModel';
import OperationCategoryModel                                     from 'Models/intervention/OperationCategoryModel';
import OperationModel                                             from 'Models/intervention/OperationModel';
import TaskGroupModel                                             from 'Models/intervention/TaskGroupModel';
import TaskModel                                                  from 'Models/intervention/TaskModel';
import TaskOperationModel                                         from 'Models/intervention/TaskOperationModel';
import TaskOperationStatusModel                                   from 'Models/intervention/TaskOperationStatusModel';
import TaskStatusModel                                            from 'Models/intervention/TaskStatusModel';
import TaskZoneGroupModel                                         from 'Models/intervention/TaskZoneGroupModel';
import TaskZoneModel                                              from 'Models/intervention/TaskZoneModel';
import ZoneContactModel                                           from 'Models/intervention/ZoneContactModel';
import BillableItemModel                                          from 'Models/invoice/BillableItemModel';
import BillableModel                                              from 'Models/invoice/BillableModel';
import BillableStatusModel                                        from 'Models/invoice/BillableStatusModel';
import BillingFrequencyModel                                      from 'Models/invoice/BillingFrequencyModel';
import BillingGroupModel                                          from 'Models/invoice/BillingGroupModel';
import CurrencyModel                                              from 'Models/invoice/CurrencyModel';
import InvoiceModel                                               from 'Models/invoice/InvoiceModel';
import InvoiceStatusModel                                         from 'Models/invoice/InvoiceStatusModel';
import InvoiceTypeModel                                           from 'Models/invoice/InvoiceTypeModel';
import InvoicingGroupModel                                        from 'Models/invoice/InvoicingGroupModel';
import PaymentModeModel                                           from 'Models/invoice/PaymentModeModel';
import PaymentModeRuleModel                                       from 'Models/invoice/PaymentModeRuleModel';
import PaymentModeTypeModel                                       from 'Models/invoice/PaymentModeTypeModel';
import VatModel                                                   from 'Models/invoice/VatModel';
import StaffMemberModel                                           from 'Models/rh/StaffMemberModel';
import StaffModel                                                 from 'Models/rh/StaffModel';
import ActivityCategoryModel                                      from 'Models/sales/ActivityCategoryModel';
import ActivityModel                                              from 'Models/sales/ActivityModel';
import ActivityTypeActivityCategoryModel                          from 'Models/sales/ActivityTypeActivityCategoryModel';
import ActivityTypeModel                                          from 'Models/sales/ActivityTypeModel';
import ChargeableModeModel                                        from 'Models/sales/ChargeableModeModel';
import ChargeableModel                                            from 'Models/sales/ChargeableModel';
import ContractActorGroupModel                                    from 'Models/sales/ContractActorGroupModel';
import ContractActorModel                                         from 'Models/sales/ContractActorModel';
import ContractIterationBillingGroupModel                         from 'Models/sales/ContractIterationBillingGroupModel';
import ContractIterationClosingModel                              from 'Models/sales/ContractIterationClosingModel';
import ContractIterationItemActivityChargeableModel               from 'Models/sales/ContractIterationItemActivityChargeableModel';
import ContractIterationItemActivityModel                         from 'Models/sales/ContractIterationItemActivityModel';
import ContractIterationItemEventModel                            from 'Models/sales/ContractIterationItemEventModel';
import ContractIterationItemEventStatusHistory                    from 'Models/sales/ContractIterationItemEventStatusHistory';
import ContractIterationItemEventStatusModel                      from 'Models/sales/ContractIterationItemEventStatusModel';
import ContractIterationItemModel                                 from 'Models/sales/ContractIterationItemModel';
import ContractIterationItemScheduleConstraintModel               from 'Models/sales/ContractIterationItemScheduleConstraintModel';
import ContractIterationModel                                     from 'Models/sales/ContractIterationModel';
import ContractIterationReconductionModel                         from 'Models/sales/ContractIterationReconductionModel';
import ContractIterationStatusHistoryModel                        from 'Models/sales/ContractIterationStatusHistoryModel';
import ContractIterationStatusModel                               from 'Models/sales/ContractIterationStatusModel';
import PurchaseOrderTargetModel                                   from 'Models/sales/PurchaseOrderTargetModel';
import ReconductabilityModel                                      from 'Models/sales/ReconductabilityModel';
import TagModel                                                   from 'Models/sales/TagModel';
import ValidationThresholdModel                                   from 'Models/sales/ValidationThresholdModel';
import { BILLABLE_STATUS_CREATED }                                from 'constants/BillableStatuses';
import { CONTRACT_ITERATION_ITEM_EVENT_STATUS_DONE_PENDING }      from 'constants/ContractIterationItemEventStatuses';
import nestedResolvable                                           from 'decorators/nestedResolvable';
import { computed }                                               from 'mobx';
import { action }                                                 from 'mobx';
import { observable }                                             from 'mobx';
import { makeObservable }                                         from 'mobx';
import AbstractModelXStore                                        from 'stores/AbstractModelXStore';
import { appStore }                                               from 'stores';
import { enterprisePartitionDashboardStore }                      from 'stores';
import { authenticationStore }                                    from 'stores';
import { groupCollectionByProperty }                              from 'tools/CollectionHelper';
import { indexCollectionByProperty }                              from 'tools/CollectionHelper';
import ConfigProxy                                                from 'tools/ConfigProxy';
import { Rights }                                                 from 'tools/Rights';
import { whenAsync }                                              from 'tools/modelxTools';

export default class ContractIterationDashboardStore extends AbstractModelXStore {

	public activityCategoryCollection = new ApiCollection(ActivityCategoryModel);
	public activityCollection = new ApiCollection(ActivityModel);
	public activityTypeActivityCategoryCollection = new ApiCollection(ActivityTypeActivityCategoryModel);
	public activityTypeCollection = new ApiCollection(ActivityTypeModel);
	public allInvoiceInvoiceCollection = new ApiCollection(InvoiceModel);
	public allInvoiceRefundCollection = new ApiCollection(InvoiceModel);
	public billableCollection = new ApiCollection(BillableModel);
	public billableItemCollection = new ApiCollection(BillableItemModel);
	public billableStatusCollection = new ApiCollection(BillableStatusModel);
	public billingFrequencyCollection = new ApiCollection(BillingFrequencyModel);
	public billingGroupCollection = new ApiCollection(BillingGroupModel);
	public buyingContractActorCollection = new ApiCollection(ContractActorModel);
	public buyingContractActorContactCollection = new ApiCollection(ContactModel);
	public chargeableCollection = new ApiCollection(ChargeableModel);
	public chargeableModeCollection = new ApiCollection(ChargeableModeModel);
	public commentSpecialConditionsCollection = new ApiCollection(CommentModel);
	public commercialContractActorCollection = new ApiCollection(ContractActorModel);
	public commercialStaffCollection = new ApiCollection(StaffModel);
	public commercialStaffMemberCollection = new ApiCollection(StaffMemberModel);
	public contractActorGroupCollection = new ApiCollection(ContractActorGroupModel);
	public contractIteration = new ContractIterationModel();
	public contractIterationBillingGroup = new ContractIterationBillingGroupModel();
	public contractIterationClosingCollection = new ApiCollection(ContractIterationClosingModel);
	public contractIterationCollection = new ApiCollection(ContractIterationModel);
	public contractIterationItemActivityChargeableCollection = new ApiCollection(ContractIterationItemActivityChargeableModel);
	public contractIterationItemActivityCollection = new ApiCollection(ContractIterationItemActivityModel);
	public contractIterationItemActivityTagCollection = new ApiCollection(TagModel);
	public contractIterationItemCollection = new ApiCollection(ContractIterationItemModel);
	public contractIterationItemEventCollection = new ApiCollection(ContractIterationItemEventModel);
	public contractIterationItemEventDonePendingCollection = new ApiCollection(ContractIterationItemEventModel);
	public contractIterationItemEventStatusCollection = new ApiCollection(ContractIterationItemEventStatusModel);
	public contractIterationItemEventStatusHistoryCollection = new ApiCollection(ContractIterationItemEventStatusHistory);
	public contractIterationItemEventToArbitrateCollection = new PagedCollection(ContractIterationItemEventModel);
	public contractIterationItemScheduleConstraintCollection = new ApiCollection(ContractIterationItemScheduleConstraintModel);
	public contractIterationReconductionAsReconductedCollection = new ApiCollection(ContractIterationReconductionModel);
	public contractIterationReconductionAsReconductionCollection = new ApiCollection(ContractIterationReconductionModel);
	public contractIterationStatusCollection = new ApiCollection(ContractIterationStatusModel);
	public contractIterationStatusHistoryCollection = new ApiCollection(ContractIterationStatusHistoryModel);
	public currencyCollection = new ApiCollection(CurrencyModel);
	public eventCollection = new PagedCollection(EventModel);
	public interventionCollection = new ApiCollection(InterventionModel);
	public interventionOperationCollection = new ApiCollection(InterventionOperationModel);
	public interventionStatusCollection = new ApiCollection(InterventionStatusModel);
	public invoiceInvoiceCollection = new PagedCollection(InvoiceModel);
	public invoiceRefundCollection = new PagedCollection(InvoiceModel);
	public invoiceStatusCollection = new ApiCollection(InvoiceStatusModel);
	public invoiceTypeCollection = new ApiCollection(InvoiceTypeModel);
	public invoicingGroup = new InvoicingGroupModel();
	public operationCategoryCollection = new ApiCollection(OperationCategoryModel);
	public operationCollection = new ApiCollection(OperationModel);
	public paymentModeCollection = new ApiCollection(PaymentModeModel);
	public paymentModeRuleCollection = new ApiCollection(PaymentModeRuleModel);
	public paymentModeTypeCollection = new ApiCollection(PaymentModeTypeModel);
	public purchaseOrderTargetCollection = new ApiCollection(PurchaseOrderTargetModel);
	public reconductabilityCollection = new ApiCollection(ReconductabilityModel);
	public staffCollection = new ApiCollection(StaffModel);
	public staffMemberCollection = new ApiCollection(StaffMemberModel);
	public taskCollection = new ApiCollection(TaskModel);
	public taskGroupCollection = new ApiCollection(TaskGroupModel);
	public taskOperationCollection = new ApiCollection(TaskOperationModel);
	public taskOperationStatusCollection = new ApiCollection(TaskOperationStatusModel);
	public taskStatusCollection = new ApiCollection(TaskStatusModel);
	public taskZoneCollection = new ApiCollection(TaskZoneModel);
	public taskZoneGroupCollection = new ApiCollection(TaskZoneGroupModel);
	public validationThresholdCollection = new ApiCollection(ValidationThresholdModel);
	public vatCollection = new ApiCollection(VatModel);
	public zoneContactCollection = new ApiCollection(ZoneContactModel);

	private _contractIterationId;

	@observable
	private _contractIterationItemSearch = '';

	public constructor() {
		super();

		makeObservable(this);
	}

	public async checkHasDebitMandate(invoicingGroupUrn: Urn) {
		const { debitMandateCollection } = enterprisePartitionDashboardStore;
		const entrepriseUrn = this.owner.company.enterprise.urn;

		await debitMandateCollection
			.setFilter('invoicingGroup.invoicingOwner.enterpriseUrn', entrepriseUrn)
			.listBy([invoicingGroupUrn], 'invoicingGroup');

		return !!debitMandateCollection.length;
	}

	public async checkIsAllowedDebit(invoicingGroupUrn: Urn) {
		const { debitMandateCollection } = enterprisePartitionDashboardStore;
		const hasDebitMandate = await this.checkHasDebitMandate(invoicingGroupUrn);

		if (hasDebitMandate) {
			await debitMandateCollection.whenIsLoaded(m => m.debitMandateStatus);
			return debitMandateCollection.some(debitMandate => debitMandate.debitMandateStatus.reference === 'actif');
		}

		return false;
	}

	public clear() {
		this.setContractIterationItemSearch('');

		return super.clear();
	}

	public listActivities = async (): Promise<void> => {
		const contractIterationId = this._contractIterationId;

		this.contractIterationItemActivityChargeableCollection.setFilter('contractIterationItemActivity.contractIterationItem.contractIteration', contractIterationId);
		this.contractIterationItemActivityChargeableCollection.list();

		this.contractIterationItemActivityCollection.setFilter('contractIterationItem.contractIteration', contractIterationId);
		this.contractIterationItemActivityCollection.setSort('contractIterationItem.position');
		this.contractIterationItemActivityCollection.setSort('position');
		await this.contractIterationItemActivityCollection.list().then(async () => {
			this.activityCollection
				.listBy(this.contractIterationItemActivityCollection.map(m => m.getId('activity')))
				.then(async () => {
					const activityTypeIds = this.activityCollection.map(m => m.getId('activityType'));
					await this.activityTypeCollection.listBy(activityTypeIds);
					await this.activityTypeActivityCategoryCollection.listBy(activityTypeIds, 'activityType');
					const operationTypeIds = this.activityTypeCollection.map(m => m.getId('operationType'));
					await this.operationCollection.listBy(operationTypeIds, 'operationType');
				});

			await this.taskZoneCollection
				.listBy(this.contractIterationItemActivityCollection.map(m => m.getId('location')))
				.then(() => {
					this.zoneContactCollection
						.setFilters({
							'contactType.reference': 'signatory',
							taskZone: this.taskZoneCollection.distinctDefinedKey('id'),
						})
						.list();
				});

			await this.taskZoneGroupCollection
				.listBy(this.taskZoneCollection.map(m => m.getId('taskZoneGroup')));

			await this.contractIterationItemActivityTagCollection
				.setFilter('targetUrn', this.contractIterationItemActivityCollection.urns)
				.list();
		});
	};

	public listEvents = () => {
		const contractIterationId = this._contractIterationId;

		return Promise.all([
			this.contractIterationItemEventCollection
				.setFilter('contractIterationItem.contractIteration', contractIterationId)
				.list(),

			this.contractIterationItemEventDonePendingCollection.setFilters({
				'contractIterationItem.contractIteration': contractIterationId,
				'contractIterationItemEventStatus.reference': [
					CONTRACT_ITERATION_ITEM_EVENT_STATUS_DONE_PENDING.reference,
				],
			}),

			this.contractIterationItemEventDonePendingCollection.list(),

			this.contractIterationItemEventStatusHistoryCollection
				.setFilter('contractIterationItemEvent.contractIterationItem.contractIteration', contractIterationId)
				.list(),
		]);
	};

	public listItems = () => {
		const contractIterationId = this._contractIterationId;

		this.contractIterationItemCollection.setSort('position');

		return this.contractIterationItemCollection.setFilter('contractIteration', contractIterationId).list().then(() => {
			const contractIterationItemsUrns = this.contractIterationItemCollection
				.map(contractIterationItem => contractIterationItem.urn);

			if (contractIterationItemsUrns.length) {
				return Promise.all([
					this.taskCollection
						.setFilter('taskGroup.sourceUrn', contractIterationItemsUrns)
						.list()
						.then(() => {
							return this.taskGroupCollection.listBy(this.taskCollection.map(t => t.getId('taskGroup')));
						}),


					this.taskOperationCollection
						.setFilters({
							'task.taskMetas.reference': 'contract_iteration_urn',
							'task.taskMetas.value': this.contractIteration.urn,
						})
						.list(),
				]);
			}
		});
	};

	public listScheduleConstraints = () => {
		const contractIterationId = this._contractIterationId;

		return this.contractIterationItemScheduleConstraintCollection
			.setFilter('contractIterationItem.contractIteration', contractIterationId)
			.list();
	};

	public async load() {
		const contractIterationId = this._contractIterationId;

		//--- ContractIteration, should be first ---//
		await this.contractIteration.setId(contractIterationId).fetch();

		if (!this.contractIteration.isLoaded) {
			return;
		}

		const { contractId } = this.contractIteration;

		await Promise.all([
			this.contractIterationClosingCollection
				.setFilter('contractIteration', contractIterationId)
				.list(),

			this.commentSpecialConditionsCollection
				.setFilter('typeSource.source.entityUrn', this.contractIteration.urn)
				.setFilter('typeSource.type.reference', 'contract_iteration_special_conditions')
				.list(),

			this.contractIterationCollection
				.setFilter('contract', contractId)
				.setSort('startDate')
				.list(),

			this.contractIterationReconductionAsReconductedCollection
				.setFilter('contractIterationReconducted', contractIterationId)
				.list(),

			this.contractIterationReconductionAsReconductionCollection
				.setFilter('contractIterationReconduction', contractIterationId)
				.list(),

			this.eventCollection
				.setFilters({
					'subject.metadata.contract_iteration_urn': this.contractIteration.urn,
					'subject.metadata.recipient_type': 'TO',
				})
				.list(),

			this.interventionCollection
				.setFilter('interventionOperations.taskOperation.task.taskMetas.reference', 'contract_iteration_urn')
				.listBy([this.contractIteration.urn], 'interventionOperations.taskOperation.task.taskMetas.value')
				.then(() => {
					return this.interventionOperationCollection
						.listBy(this.interventionCollection.ids, 'intervention');
				}),

			this.paymentModeCollection.setFilter('partitionUrn', ConfigProxy.get('PARTITION_URN')).list(),

			this.purchaseOrderTargetCollection.setFilter('targetUrn', this.contractIteration.urn).list(),

			this.reconductabilityCollection.setFilter('contract', contractId).list(),

			whenAsync(() => this.owner.enterprise.isLoaded).then(async () => {
				if (this.owner.enterprise.getUrn('nafCode')) {
					await this.paymentModeRuleCollection
						.setFilters({
							'paymentMode.partitionUrn': appStore.partition.urn,
							targetUrn: this.owner.enterprise.getUrn('nafCode'),
						})
						.list();
				}
			}),

			this.listActivities(),
			this.listEvents(),
			this.listItems(),
			this.listScheduleConstraints(),
			this.loadActorAndContactCollection(),
			this.loadBillingAndInvoicingInformations(),
			this.loadContractIterationStatusHistoryCollection(),
			this.loadInvoiceCollection(),
			this.loadNotManageableCollections(),
		]);


		this.validationThresholdCollection.setFilters({
			'ownerSubPartition.subPartitionUrn': appStore.subPartitionUrn,
			staffMemberUrn: authenticationStore.session.ownerUrn,
		});
		await this.validationThresholdCollection.list();
	}

	public loadActorAndContactCollection = async () => {
		const contractId = this.contractIteration.contractId;

		if (!contractId) {
			console.warn('No contract id');
			return;
		}

		await Promise.all([
			this.buyingContractActorCollection
				.setFilters({
					'contract': contractId,
					'contractActorGroup.reference': 'CONTRACT_ACTOR_GROUP_BUYING',
				})
				.list()
				.then(() => {
					return this.buyingContractActorContactCollection
						.listByFromCollection(this.buyingContractActorCollection, 'actorId', 'id');
				}),

			this.commercialContractActorCollection
				.setFilters({
					'contract': contractId,
					'contractActorGroup.reference': 'CONTRACT_ACTOR_GROUP_COMMERCIAL',
				})
				.list()
				.then(async () => {
					await this.commercialStaffMemberCollection
						.listByFromCollection(this.commercialContractActorCollection, 'actorId', 'id');

					await this.commercialStaffCollection
						.listBy(this.commercialStaffMemberCollection.map(m => m.getId('staff')));
				}),
		]);
	};

	public loadBillingAndInvoicingInformations = () => {
		this.billingGroupCollection.clear();
		this.contractIterationBillingGroup.clear();
		this.invoicingGroup.clear();

		if (this.contractIteration.contractIterationBillingGroupId) {
			return this.contractIterationBillingGroup
				.setId(this.contractIteration.contractIterationBillingGroupId)
				.fetch()
				.then(() => {
					this.billingGroupCollection
						.setFilter('sourceUrn', this.contractIterationBillingGroup.urn)
						.list()
						.then(() => {
							this.billableCollection
								.listByFromCollection(this.billingGroupCollection, 'id', 'billingGroup', {}, {
									'billableStatus.reference': [BILLABLE_STATUS_CREATED.reference],
								})
								.then(() => {
									this.billableItemCollection.listByFromCollection(this.billableCollection, 'id', 'billable');
								});
						})
						.catch(() => {
							// the user could not have the right to get billables
						});

					this.invoicingGroup.setId(this.contractIterationBillingGroup.invoicingGroupId);
					this.invoicingGroup.fetch();
				});
		}
	};

	public loadContractIterationStatusHistoryCollection = () => {
		return this.contractIterationStatusHistoryCollection
			.setFilter('contractIteration', this.contractIteration.id)
			.setSort('createdAt', false)
			.list()
			.then(async () => {
				await this.staffMemberCollection
					.listByFromCollection(this.contractIterationStatusHistoryCollection, 'createdById', 'id');
				await this.staffCollection.listBy(this.staffMemberCollection.map(m => m.getId('staff')));
			});
	};

	public loadInvoiceCollection = () => {
		return Promise.all([
			this.allInvoiceInvoiceCollection
				.setFilters({
					'billingGroup.billingGroupMetas.value': this.contractIteration.urn,
					'invoiceType.reference': 'invoice',
				})
				.list(),

			this.invoiceInvoiceCollection
				.setFilters({
					'billingGroup.billingGroupMetas.value': this.contractIteration.urn,
					'invoiceType.reference': 'invoice',
				})
				.setSort('date')
				.list(),

			this.allInvoiceRefundCollection
				.setFilters({
					'billingGroup.billingGroupMetas.value': this.contractIteration.urn,
					'invoiceType.reference': 'refund',
				})
				.list(),

			this.invoiceRefundCollection
				.setFilters({
					'billingGroup.billingGroupMetas.value': this.contractIteration.urn,
					'invoiceType.reference': 'refund',
				})
				.setSort('date')
				.list(),
		]);
	};

	public loadNotManageableCollections = () => {
		this.operationCategoryCollection.setFilter('partitionUrn', ConfigProxy.get('PARTITION_URN'));

		return Promise.all([
			this.billableStatusCollection.list({ cache: 3600 }),
			this.billingFrequencyCollection.list({ cache: 3600 }),
			this.chargeableCollection.list({ cache: 3600 }),
			this.chargeableModeCollection.list({ cache: 3600 }),
			this.contractActorGroupCollection.list({ cache: 3600 }),
			this.activityCategoryCollection.list({ cache: 3600 }),
			this.contractIterationItemEventStatusCollection.list({ cache: 3600 }),
			this.contractIterationStatusCollection.list({ cache: 3600 }),
			this.currencyCollection.list({ cache: 3600 }),
			this.interventionStatusCollection.list({ cache: 3600 }),
			this.invoiceStatusCollection.list({ cache: 3600 }),
			this.invoiceTypeCollection.list({ cache: 3600 }),
			this.operationCategoryCollection.list({ cache: 3600 }),
			this.paymentModeTypeCollection.list({ cache: 3600 }),
			this.taskOperationStatusCollection.list({ cache: 3600 }),
			this.taskStatusCollection.list({ cache: 3600 }),
			this.vatCollection.list(),
		]);
	};

	@computed
	public get contractIterationItemActivityByTaskZoneUrn() {
		return groupCollectionByProperty(this.contractIterationItemActivityCollection, 'locationUrn');
	}

	@computed
	public get filteredTaskZoneCollection() {
		const filteredTaskZoneCollection = new ApiCollection(TaskZoneModel);
		const search = this.contractIterationItemSearch.trim().toLowerCase();

		this.taskZoneCollection.forEach(taskZone => {
			// On filtre les résultats selon le champ de recherche
			if (search) {
				const itemActivities = this.contractIterationItemActivityByTaskZoneUrn[taskZone.urn] || [];

				if (
					// Cherche dans le titre du site
					!taskZone.name.toLowerCase().includes(search)
					// Cherche dans le titre de la prestation
					&& !itemActivities.every(m => m.contractIterationItem.title?.toLowerCase().includes(search))
				) {
					return false;
				}
			}

			if (this.contractIterationItemActivityByTaskZoneUrn[taskZone.urn]) {
				filteredTaskZoneCollection.add(taskZone);
			}
		});
		return filteredTaskZoneCollection;
	}

	@computed
	public get taskZoneByTaskZoneGroupIri() {
		return groupCollectionByProperty(this.filteredTaskZoneCollection, 'taskZoneGroup.@id');
	}

	@computed
	public get activityById() {
		return indexCollectionByProperty(this.activityCollection, 'id');
	}

	@computed
	public get taskZoneGroupByIri() {
		return indexCollectionByProperty(this.taskZoneGroupCollection, '@id');
	}

	@computed
	public get chargeableById() {
		return indexCollectionByProperty(this.chargeableCollection, 'id');
	}

	@computed
	public get chargeableModeById() {
		return indexCollectionByProperty(this.chargeableModeCollection, 'id');
	}

	@computed
	public get operationById() {
		return indexCollectionByProperty(this.operationCollection, 'id');
	}

	@computed
	public get contractIterationItemActivityByContractIterationItemId() {
		return groupCollectionByProperty(this.contractIterationItemActivityCollection, 'contractIterationItem.id');
	}

	@computed
	public get contractIterationItemActivityTagByContractIterationItemActivityUrn() {
		return groupCollectionByProperty(this.contractIterationItemActivityTagCollection, 'targetUrn');
	}

	@computed
	public get contractIterationItemActivityChargeableByContractIterationItemActivityId() {
		return groupCollectionByProperty(this.contractIterationItemActivityChargeableCollection, 'contractIterationItemActivity.id');
	}

	@computed
	public get contractIterationItemEventByContractIterationItemId() {
		return groupCollectionByProperty(this.contractIterationItemEventCollection, 'contractIterationItem.id');
	}

	@computed
	public get contractIterationItemScheduleConstraintByContractIterationItemId() {
		return groupCollectionByProperty(this.contractIterationItemScheduleConstraintCollection, 'contractIterationItem.id');
	}

	@computed
	public get contractIterationIsUpdatable() {
		return Rights.contractIteration.isUpdatable(this.contractIterationStatus);
	}

	@computed
	public get contractIterationEventsAreUpdatable() {
		return Rights.contractIteration.eventsAreUpdatable(this.contractIterationStatus);
	}

	@computed
	public get contractIterationActivitiesVatsAreUpdatable() {
		return Rights.contractIteration.activitiesVatsAreUpdatable(this.contractIterationStatus);
	}

	@computed
	public get contractIterationTotalPriceExclTaxWithoutDiscount() {
		return this.contractIteration.totalPriceExclTax;
	}

	@computed
	public get contractIterationTotalPriceExclTaxWithDiscount() {
		return this.contractIteration.totalPriceExclTax
			- (this.contractIteration.totalPriceExclTax * this.contractIteration.discount / 100);
	}

	@computed
	public get billingGroup() {
		return this.billingGroupCollection.last();
	}

	@computed
	public get billingFrequency() {
		const billingFrequency = this.billingFrequencyCollection.find(
			billingFrequency => billingFrequency.id === this.contractIterationBillingGroup.billingFrequencyId,
		);

		if (!billingFrequency) {
			return new BillingFrequencyModel();
		}

		return billingFrequency;
	}

	@computed
	public get contractIterationStatus() {
		const contractIterationStatus = this.contractIterationStatusCollection.find(
			contractIterationStatus => contractIterationStatus.iri === this.contractIteration.contractIterationStatusIri,
		);

		if (!contractIterationStatus) {
			return new ContractIterationStatusModel();
		}

		return contractIterationStatus;
	}

	@computed
	public get contractIterationClosing() {
		return this.contractIterationClosingCollection.first() || undefined;
	}

	@computed
	public get contractIterationStatusHistoryValidatedByClient() {
		const validatedByClientStatus = this.contractIterationStatusCollection.find(
			contractIterationStatus => contractIterationStatus.reference ===
				'validated_by_client',
		);

		if (!validatedByClientStatus) {
			return new ContractIterationStatusHistoryModel();
		}

		const contractIterationStatusHistoryValidatedByClient = this.contractIterationStatusHistoryCollection.find(
			contractIterationStatusHistory => contractIterationStatusHistory.contractIterationStatusId === validatedByClientStatus.id,
		);

		if (!contractIterationStatusHistoryValidatedByClient) {
			return new ContractIterationStatusHistoryModel();
		}

		return contractIterationStatusHistoryValidatedByClient;
	}

	@computed
	public get staffContractActor() {
		const lastStaffContractActor = this.commercialContractActorCollection.last();

		if (!lastStaffContractActor) {
			return new ContractActorModel();
		}

		return lastStaffContractActor;
	}

	public set contractIterationId(contractIterationId) {
		this._contractIterationId = contractIterationId;
		this.load();
	}

	@computed
	public get totalStucked() {
		return this.contractIterationItemEventDonePendingCollection.models.reduce(
			(previousValue, val) => {
				const contractIterationItem = this.contractIterationItemCollection
					.getById(val.getId('contractIterationItem'));

				if (!contractIterationItem) {
					return previousValue;
				}

				const totalActivities = this.contractIterationItemActivityCollection.models.reduce(
					(previousValue, currentValue) => {
						if (currentValue.getId('contractIterationItem') !== contractIterationItem.id) {
							return previousValue;
						}

						return previousValue + currentValue.totalPriceExclTax;
					},
					0,
				);

				return previousValue + totalActivities;
			},
			0,
		);
	}

	@nestedResolvable()
	public get contract() {
		return this.contractIteration.contract;
	}

	@nestedResolvable()
	public get owner() {
		return this.contractIteration.contract.owner;
	}

	@nestedResolvable()
	public get client() {
		return this.contractIteration.contract.owner.client;
	}

	@action
	public setContractIterationItemSearch(value: string) {
		this._contractIterationItemSearch = value;
	}

	public get contractIterationItemSearch() {
		return this._contractIterationItemSearch;
	}

	@computed
	public get taskZonesWithoutContactSignatory() {
		const taskZones: TaskZoneModel[] = [];
		if (!this.zoneContactCollection.length) {
			return taskZones;
		}

		this.taskZoneCollection.forEach(tz => {
			if (!this.zoneContactCollection.find(zc => zc.taskZoneId === tz.id)) {
				taskZones.push(tz);
			}
		});

		return taskZones;
	}

	@computed
	public get reconductability() {
		return this.reconductabilityCollection.first();
	}
}
